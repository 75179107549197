<template>
    <div>
        <main-nav fixed></main-nav>
        <l-right-contact></l-right-contact>
        <div class="banner">
            <video src="https://static.ycsd.work//site/ai/gls.mp4" muted autoplay loop></video>
            <div class="banner-content">
                <img class="banner-title" ondragstart="return false" src="@/assets/images/fashion/qfm_banner_title.png"
                     alt="">

                <div class="banner-buttons">

                    <a class="banner-button">
                        <img ondragstart="return false" src="@/assets/images/fashion/banner-btn-1.png" alt="">
                        开课日期
                    </a>

                    <a class="banner-button">
                        <img ondragstart="return false" src="@/assets/images/fashion/banner-btn-2.png" alt="">
                        咨询课程
                    </a>


                    <a class="banner-button" href="/sign-up-process">
                        <img ondragstart="return false" src="@/assets/images/fashion/banner-btn-3.png" alt="">
                        报名流程
                    </a>

                </div>
            </div>
        </div>

        <div class="cla-container">
            <div>
                <img class="title-img" ondragstart="return false" src="@/assets/images/fashion/sd_cla_1.png" alt="">
            </div>
            <div>
                <img class="content-img" ondragstart="return false"
                     src="https://static.ycsd.work/site/fashion/qfm_clas_text.png"
                     alt="">
            </div>
        </div>

        <div class="ofl-container">
            <img class="content-img i1" ondragstart="return false"
                 src="https://static.ycsd.work/site/fashion/ofl-1.png" alt="">
            <img class="content-img i2" ondragstart="return false"
                 src="https://static.ycsd.work/site/fashion/ofl-2.png" alt="">
            <img class="content-img i3" ondragstart="return false"
                 src="https://static.ycsd.work/site/fashion/ofl-3.png" alt="">
        </div>


        <l-spc></l-spc>

        <l-schedule :schedule="schedule"></l-schedule>

        <l-teacher></l-teacher>

        <l-contact></l-contact>

        <l-footer></l-footer>

        <l-icp></l-icp>
    </div>
</template>

<script>
    import MainNav from "../../components/MainNav";
    import LRightContact from "../../components/LRightContact";
    import LSpc from "../../components/LSpc";
    import LTeacher from "../../components/LTeacher";
    import LContact from "../../components/LContact";
    import LFooter from "../../components/LFooter";
    import LIcp from "../../components/LIcp";
    import LSchedule from "../../components/LSchedule";
    import meta from "../../meta";

    export default {
        name: "FashionManager",
        components: {LSchedule, LIcp, LFooter, LContact, LTeacher, LSpc, LRightContact, MainNav},
        metaInfo: meta.appendTitle('量化时尚管理师'),
        data() {
            return {
                schedule: {
                    list: [
                        {
                            name: '行业分析&职业前景',
                            en_name: 'Industry analysis & career prospects',
                            time: 3,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.时尚造型穿搭师的角色价值及素养要求',
                                            sc: true
                                        }, {
                                            name: '2.时尚造型穿师的广泛工作应用场景',
                                        }, {
                                            name: '3.国际趋势对搭配师未来的影响',
                                            sc: true
                                        }, {
                                            name: '4.职业必备核心技能与自我 IP 价值',
                                        }
                                    ],
                                }, {
                                    name: '收获',
                                    list: [
                                        {
                                            name: '学员能够宏观了解行业现状及搭配师角色的重要性',
                                            style: 2
                                        }
                                    ]
                                }
                            ],

                        },
                        {
                            name: '中外百年服装发展史',
                            en_name: 'A century of clothing development history at home and abroad',
                            time: 6,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.从1900年-2000年中外百年服装发展史及经典look解析',
                                            style: 1,
                                        }, {
                                            name: '2.经典品牌代表及设计大师风格解读',
                                        }, {
                                            name: '3.了解当今服装品牌的分层分类',
                                        },
                                    ]
                                }, {
                                    name: '实践',
                                    list: [
                                        {
                                            name: '小组阐述百年服装代表元素与时尚品牌的定位和分类'
                                        }
                                    ]
                                }, {
                                    name: '课程亮点',
                                    list: [
                                        {
                                            name: '用大量的中外明星图片、电影电视剧剧照举例示范，带领学员了解中西方对服饰的文化差异以及代表元素，了解时装品牌的定位和分类。'
                                        }
                                    ]
                                }
                            ],
                        },
                        {
                            name: '高阶造型进阶板块',
                            en_name: 'Advanced section of advanced modeling',
                            time: 18,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.体型与整体造型的弥补关系'
                                        }, {
                                            name: '2.掌握配饰与风格塑造关系'
                                        }, {
                                            name: '3.如何把控整体造型的高级感'
                                        },
                                    ],
                                },
                                {
                                    name: '实践',
                                    list: [
                                        {
                                            name: '小组2-3人一组量体得出体型结论，并且给对方造型。组合男女14个风格配饰灵感板，5个造型高级感把控灵感板。'
                                        }
                                    ]
                                },
                                {
                                    name: '亮点',
                                    list: [
                                        {
                                            name: '学员能够通过课程内容与练习高阶造型搭配的方法，培养美感整体思维，学会多维度审美观抽离式做造型搭配' +
                                                '<br /> 讲师在点评中提升学生对高阶造型美感的敏锐度及洞察力。同时，在点评过程中活学活用，学习更多的知识干货。'
                                        }
                                    ]
                                }
                            ],
                        }, {
                            name: '衣橱科学架构板块',
                            en_name: 'Wardrobe Science Architecture Section',
                            time: 9,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.衣橱收纳整理的整体思路和实施步骤'
                                        }, {
                                            name: '2.衣橱的科学配比与管理'
                                        }
                                    ]
                                },
                                {
                                    name: '实践',
                                    list: [
                                        {
                                            name: '小组模拟衣橱管理场景和整个流程，剪贴风格单品衣橱灵感版。',
                                            style: 2
                                        }
                                    ]
                                },
                                {
                                    name: '亮点',
                                    list: [
                                        {
                                            name: '用大量的单品图片举例，看衣橱构成图了解男女14大风格的特征衣橱以及必备单品组合形式，掌握每个衣橱风格的特性与单品组合形式。',
                                            style: 2
                                        }
                                    ]
                                }
                            ],
                        }, {
                            name: '高阶妆发造型板块',
                            en_name: 'High-end makeup and hair styling section',
                            time: 12,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.肤质与皮肤管理'
                                        }, {
                                            name: '2.妆容特征及化妆技法',
                                        }, {
                                            name: '3.发型特征及风格氛围关系',
                                        }
                                    ]
                                }, {
                                    name: '实践',
                                    list: [
                                        {
                                            name: '1.小组根据老师选题结合整体造型实操14大风格妆容与风格发型展示。'
                                        }
                                    ]
                                }, {
                                    name: '亮点',
                                    list: [
                                        {
                                            name: '带领学员了解熟知妆容发型的分类及与服饰风格的搭配方法，了解妆容发型对性格和气质的可视化展现及心理情感关系。'
                                        }
                                    ]
                                }
                            ],
                        }, {
                            name: '新媒体个人IP打造板块',
                            en_name: 'New media personal IP building section',
                            time: 12,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.数字经济时代下如何在自媒体拥有自己的时尚阵地',
                                        },
                                        {
                                            name: '2.手机、单反人像拍照构图技巧',
                                        },
                                        {
                                            name: '3.短视频拍摄的技巧和后期剪辑方法',
                                        },
                                        {
                                            name: '4.穿搭自媒体文案的整体思路及写作技巧',
                                        }
                                    ]
                                },{
                                    name: '实践',
                                    list: [
                                        {
                                            name: '分小组用手机拍摄穿搭照片并P图，学会做封面图，并写穿搭文案',
                                        }
                                    ]
                                },{
                                    name: '亮点',
                                    list: [
                                        {
                                            name: '带领学员学会使用手机和单反给自己或他人拍摄具有高级感的人物造型图片，学会拍摄符合时尚博主水准的穿搭视频，学会剪辑穿搭视频，学会写穿搭自媒体文案。',
                                        }
                                    ]
                                }
                            ],
                        }, {
                            name: '商业造型策划与实战',
                            en_name: 'Commercial modeling planning and actual combat',
                            time: 9,
                            points: 20,
                            detail: [
                                {
                                    name: '理论',
                                    list: [
                                        {
                                            name: '1.量化时尚管理师应当具备的整体商业造型策划思路',
                                        },
                                        {
                                            name: '2.当今商业环境，客户的整体造型思路、理论方法',
                                        },
                                        {
                                            name: '3.VIP客户作为素人的整体造型提升改造方法及实操步骤',
                                        },
                                        {
                                            name: '4.快速识别客户的风格、色彩、身型、气质、性格，并根据客户职业或生活场景需求把控6大属性特点融合后的整体造型美感。'
                                        }
                                    ]
                                },
                                {
                                    name: '实践',
                                    list: [
                                        {
                                            name: '小组项目：素人改造  <br /> Before&After造型纪录片拍摄 <br /> 素人客户私人风尚大片拍摄',
                                            style: 2
                                        }
                                    ]
                                }
                            ],
                        }, {
                            name: '人工智能与未来时尚产业',
                            en_name: 'Artificial Intelligence and the Future Fashion Industry',
                            time: 2,
                            points: 20,
                            detail: [],
                        }, {
                            name: '毕业答辩\\颁布证书\\现场签约',
                            en_name: 'Graduation defense\\issue certificate\\site signing',
                            time: 3,
                            points: 20,
                            detail: [],
                        },
                    ],
                    pdf: '',
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .banner {
        height: 1080px;
        width: 100%;
        background-image: url("../../assets/images/fashion/qfm_banner_bg.png");
        background-size: contain;
        padding: 1px;
        overflow: hidden;

        video {
            width: 100%;
            height: auto;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
        }
    }

    .banner-content {
        width: 1200px;
        max-width: 100%;
        text-align: left;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }

    .banner-title {
        margin-top: 240px;
        margin-bottom: 140px;
        width: 700px;
    }

    .banner-button {
        img {
            margin-right: 20px;
        }

        width: 284px;
        height: 120px;
        text-align: center;
        display: inline-block;
        background: #0D392E;
        line-height: 120px;

        font-size: 32px;
        font-weight: 500;
        color: #FFFFFF;
        letter-spacing: 1px;
        margin-right: 20px;
    }

    .cla-container {
        padding: 1px;

        .title-img {
            width: 470px;
            margin-top: 100px;
            margin-bottom: 60px;
        }

        .content-img {
            width: 1200px;
            max-width: 100%;
            margin-bottom: 100px;
        }
    }

    .ofl-container {
        padding: 1px;
        background-color: rgba(16, 16, 16, 1);
        text-align: center;

        img {
            display: inline-block;
            width: 1200px;
            max-width: 100%;
        }

        .i1 {
            margin-top: 150px;
            margin-bottom: 150px;
        }

        .i2 {
            margin-bottom: 133px;
        }

        .i3 {
            margin-bottom: 150px;
        }
    }
</style>